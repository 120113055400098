import React from 'react'

function Nav() {
    return (
        <div>
            <header class="text-gray-600 body-font bg-gradient-to-r from-[#A100FF] to-[#00FFDA] backdrop-blur-lg p-4 hidden md:block" >
                <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    {/* <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
                            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                        </svg>
                        <span class="ml-3 text-xl">Tailblocks</span>
                    </a> */}
                    <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
                        <a class="mr-5 text-black text-[24px] font-bold cursor-pointer" href='#home'>Home</a>
                        <a class="mr-5 text-black text-[24px] font-bold cursor-pointer" href='#about'>About</a>
                        <a class="mr-5 text-black text-[24px] font-bold cursor-pointer" href='#how'>How to Buy</a>
                        <a class="mr-5 text-black text-[24px] font-bold cursor-pointer" href='#token'>Tokenomics</a>
                        {/* <a class="mr-5 text-black text-[24px] font-bold cursor-pointer">Memes</a> */}

                    </nav>
                    {/* <button class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Button
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button> */}
                </div>
            </header>
        </div>
    )
}

export default Nav