import logo from './logo.svg';
import './App.css';
import Head from './Components/Head';
import Nav from './Components/Nav';
import HowToBuy from './Components/Tokenomics';
import Border from './Components/Border';
import Token from './Components/Token';
import About from './Components/About';

function App() {
  return (
    <div className='bg-black'>
      <Nav/>
     <Head/>
     <Border/>
     <About/>
     <Border/>
     <Token/>
     <Border/>
     <HowToBuy/>
     <Border/>

    </div>
  );
}

export default App;
