import React from 'react'
import './head.css'
const Head = () => {
    return (
        <div className='bg-main min-h-[100vh]'>
            <section class="text-gray-600 body-font ">
                <div class="container` mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img class="mb-[0px]" alt="hero" src={require('../Assets/logo.png')} />
                    {/* </div> */}
                    <div class="text-center lg:w-2/3 w-full">
                        <h1 class="title-font  mb-4 font-medium text-[70px]  md:text-[100px] bg-gradient-to-r from-[#81ffec] to-[#a60eff] bg-clip-text text-transparent ">Wlf On Sol</h1>
                        <p class="mb-8 leading-relaxed text-white text-[20px] md:text-[40px]">Welcome to World Liberty Financial $WLF
                            Trump’s new DEFI project Parody on Solana</p>
                        <div class="flex justify-center">
                            <a href='https://x.com/wlfsol'>
                                <button class="bg-gradient-to-r from-[#A100FF] to-[#00FFDA] text-white font-bold py-2 px-6 text-[24px] rounded ">Twitter</button>
                            </a>
                            <a href='https://t.me/WLFOnSol'>
                                <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none text-[24px] hover:bg-gray-200 rounded ">Telegram</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Head