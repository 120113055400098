import React from 'react'

const About = () => {
    return (
        <div className='py-8' id='about'>
            <div className='my-4'>
                <p className=' text-center text-[40px] md:text-[80px]  bg-gradient-to-r from-[#81ffec] to-[#a60eff] bg-clip-text text-transparent mb-10 '>About</p>
            </div>
            <div className='flex justify-center items-center'>
                <img className='max-w-full md:max-w-[70%]' src={require('../Assets/about.jpeg')} />

            </div>
        </div>
    )
}

export default About