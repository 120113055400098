import React from 'react'

const Border = () => {
    return (
        <div>
            <div class="h-2 border-b-4 border-transparent bg-gradient-to-r from-[#A100FF] to-[#00FFDA]">
            </div>
        </div>
    )
}

export default Border