import React from 'react'

const Token = () => {
    return (
        <div id='token'>
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">

                    <div>
                        <p className=' text-center text-[40px] md:text-[80px]  bg-gradient-to-r from-[#81ffec] to-[#a60eff] bg-clip-text text-transparent mb-10 '>TOKENOMICS</p>
                    </div>
                    {/* <div class="flex flex-col text-center w-full mb-20">
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-white">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
                    </div> */}
                    <div class="flex flex-wrap -m-4 text-center">
                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <div class="flex-shrink-0 w-24 mb-4 h-24 bg-[rgba(0,255,218,0.1)] text-indigo-500 rounded-full inline-flex items-center justify-center">

                                    <img src={require('../Assets/logo.png')} />
                                </div>
                                <h2 class="title-font font-medium text-3xl text-[#00FFDA]">100 Billion</h2>
                                <p class="leading-relaxed text-[#B14AED]">Total Supply</p>
                            </div>
                        </div>
                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <div class="flex-shrink-0 w-24 mb-4 h-24 bg-[rgba(0,255,218,0.1)] text-indigo-500 rounded-full inline-flex items-center justify-center">

                                    <img src={require('../Assets/burn.png')} />
                                </div>
                                <h2 class="title-font font-medium text-3xl text-[#00FFDA]">100%</h2>
                                <p class="leading-relaxed text-[#B14AED] ">Lp Burned</p>
                            </div>
                        </div>
                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <div class="flex-shrink-0 w-24 mb-4 h-24 bg-[rgba(0,255,218,0.1)] text-indigo-500 rounded-full inline-flex items-center justify-center">
                                    <img src={require('../Assets/revoke.png')} />
                                </div>
                                <h2 class="title-font font-medium text-3xl text-[#00FFDA]">REVOKED</h2>
                                <p class="leading-relaxed text-[#B14AED]">Freeze/MINT Auth</p>
                            </div>
                        </div>
                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                            <div class="flex-shrink-0 w-24 mb-4 h-24 bg-[rgba(0,255,218,0.1)] text-indigo-500 rounded-full inline-flex items-center justify-center">
                                    <img src={require('../Assets/zero.png')} />
                                </div>
                                <h2 class="title-font font-medium text-3xl text-[#00FFDA]">TAX ZERO</h2>
                                <p class="leading-relaxed text-[#B14AED]">Buy/Sell Tax 0%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Token